import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Radio, Text, RadioGroup } from "@ui-kitten/components";
import axios from "axios";
import { View, StyleSheet } from "react-native";

import { HOSTNAME } from "../../utils/ajax";

const LIST = new Array(120)
  .fill(0)
  .map((_, index) => index + 1)
  .filter((item) => item >= 18);

const MinAge = (props: any) => {
  const [min_age, setMinAge] = React.useState(18);

  const setMinAges = async (age: number) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        { min_age: age },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setMinAge(getIndex(res?.data[0]?.min_age));
    } catch (error) {
      console.error(error);
    }
  };

  const getOptions = (index: number) => {
    return LIST[index];
  };

  const getIndex = (item: any) => {
    return LIST.findIndex((element) => element === item);
  };

  return (
    <Layout>
      <Card>
        <Text>Minimum Age</Text>
        <RadioGroup
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          onChange={async (index) => {
            await setMinAges(Number(getOptions(index)));
          }}
        >
          {LIST.map((item, index) => (
            <Radio key={index} checked={Number(min_age) === Number(item)}>
              {Number(item)}
            </Radio>
          ))}
        </RadioGroup>
      </Card>
    </Layout>
  );
};

export default MinAge;
