import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Card, Input, Layout, Modal, Text } from "@ui-kitten/components";
import axios, { get } from "axios";
import {
  StyleSheet,
  Pressable,
  useWindowDimensions,
  View,
  Image,
} from "react-native";
import { Button } from "react-native-paper";
import SocketIOClient from "socket.io-client";
import { useNavigation } from "@react-navigation/native";
import Loader from "../components/loaders/Spinner";
import {
  black,
  darkGrey,
  lightGrey,
  white,
  primary,
  maxWidth,
} from "../styles/theme";
import {
  fetchSubscription,
  HOSTNAME,
  CHAT_HOSTNAME,
  redirectToHomepageWhenExpired,
} from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";
import Options from "../components/popups/Options";
import ConnectFour from "../components/games/versus/ConnectFour";
import TicTacToe from "../components/games/versus/TicTacToe";

const LIST_OF_GAMES = [
  {
    name: "Tic Tac Toe",
    component: TicTacToe,
    image: require("../../assets/images/tic-tac-toe.png"),
  },
  {
    name: "Connect Four",
    component: ConnectFour,
    image: require("../../assets/images/connect-four.png"),
  },
  {
    name: "Quit Game",
    component: null,
    image: require("../../assets/images/X.png"),
  },
];

const Chat: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const layout = useWindowDimensions();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [menu, setMenu] = React.useState(false);
  const [currentGame, setCurrentGame]: any = React.useState(null);
  const [messages, setMessages]: any = React.useState([]);
  const [messageThread, setMessageThread]: any = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [userId, setUserId] = React.useState(0);
  const [text, setText] = React.useState("");
  const [socket, setSocket]: any = React.useState(null);

  const selectGame = (data: any) => {
    if (data.game === "Quit Game") {
      setCurrentGame(null);
    } else {
      const game = LIST_OF_GAMES.find((game) => game.name === data.game);
      setCurrentGame(null);
      setCurrentGame(game);
    }
  };

  const getSocket = (id: number): any => {
    return SocketIOClient(CHAT_HOSTNAME, {
      query: { id },
      withCredentials: true,
    });
  };

  const loadUser = async () => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const fetchMessageThread = async () => {
    try {
      setLoading(true);
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/user_matches/message_threads?user_id=${route?.params?.userId}&match_user_id=${route?.params?.matchId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessageThread(data || {});
      setSocket(getSocket(data?.data?.id));
      setLoading(false);
      return data?.data || {};
    } catch (error) {
      console.error(error);
      setLoading(false);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const fetchMessages = async () => {
    try {
      const { id } = await fetchMessageThread();
      setLoading(true);
      const token = await AsyncStorage.getItem("token");

      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/message_threads/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessages(data?.data || []);
      setLoading(false);
      return data?.data || [];
    } catch (error) {
      console.error(error);
      setLoading(false);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const sendMessage = async (message: string) => {
    try {
      setLoading(true);
      const id = messageThread?.data?.id;
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.post(
        `${HOSTNAME}/api/v1/message_threads/${id}`,
        { message: message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const allMessages = [
        ...(Array.isArray(messages) ? messages : []),
        data?.data || { message },
      ];
      setMessages(allMessages);
      socket?.emit("data", {
        type: "message",
        message: data?.data?.message,
        userId: data?.data?.user_id,
        id: data?.data?.id,
      });
      setText("");
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
    setLoading(false);
  };

  const renderGame = () => {
    const Component = currentGame?.component;
    if (!Component) return null;
    console.log(messageThread);
    return <Component date={messageThread} socket={socket} route={route} />;
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    loadUser().catch(console.error);
  }, [userId]);

  React.useEffect(() => {
    fetchMessages().catch(console.error);
  }, []);

  React.useEffect(() => {
    socket?.on("data", (data: any) => {
      console.log(data);
      if (data?.type === "message") {
        fetchMessages()
          .then((mess: any) => setMessages([...mess]))
          .catch(console.error);
      } else if (data?.type === "gameSelected") {
        selectGame(data);
      }
    });
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <View style={styles.row}>
        <View>
          <Pressable onPress={fetchMessages}>
            <Text style={styles.icon}>⟳</Text>
          </Pressable>
        </View>
        <Text category="h1">Chat</Text>
        <View>
          <Pressable onPress={() => setMenu(true)}>
            <Text style={styles.icon}>🎮</Text>
          </Pressable>
          <Options
            navigation={navigation}
            route={route}
            matchId={route?.params?.matchId}
          />
        </View>
      </View>
      <View style={styles.row}>{renderGame()}</View>
      <Card style={styles.layout}>
        {Array.isArray(messages) &&
          messages
            ?.filter((e) => e.message)
            ?.sort((a, b) => a.id - b.id)
            ?.map((message: any, index: number) => (
              <View style={Object.assign({}, styles.chatContainer)} key={index}>
                <View
                  style={Object.assign({}, styles.bubbleContainer, {
                    justifyContent:
                      message?.user_id !== Number(userId)
                        ? "flex-start"
                        : "flex-end",
                    maxWidth: "100%",
                    width: "100%",
                  })}
                >
                  <Text
                    style={Object.assign(
                      {},
                      message?.user_id === Number(userId)
                        ? styles.chatUserText
                        : styles.chatOtherText,
                      styles.bubbleContainer,
                      {
                        justifyContent:
                          message?.user_id !== Number(userId)
                            ? "flex-start"
                            : "flex-end",
                      }
                    )}
                  >
                    {message?.message || ""}
                  </Text>
                </View>
              </View>
            ))}
        {loading ? <Loader /> : null}
        <Input
          style={styles.textInput}
          placeholder="Enter text"
          onChangeText={(txt: any) => {
            setText(txt);
          }}
          value={text}
        />
        <Button
          style={styles.button}
          onPress={() => {
            sendMessage(text).catch(console.error);
          }}
          disabled={!text}
        >
          <Text style={styles.buttonText}>Send</Text>
        </Button>
      </Card>
      <Modal
        visible={menu}
        backdropStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        onBackdropPress={() => setMenu(false)}
      >
        <View>
          <View>
            <Text>Menu</Text>
          </View>
          <View>
            {LIST_OF_GAMES.map((gameObj, key) => (
              <Pressable
                key={key}
                onPress={() => {
                  setCurrentGame(null);
                  setCurrentGame(gameObj);
                  socket.emit("data", {
                    type: "gameSelected",
                    game: gameObj.name,
                  });
                  setMenu(false);
                }}
              >
                <View>
                  <Image
                    style={{ height: 50, width: 50 }}
                    source={gameObj.image}
                  />
                  <Text style={{ color: "white" }}>{gameObj.name}</Text>
                </View>
              </Pressable>
            ))}
          </View>
        </View>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  bubbleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  button: {
    backgroundColor: primary,
    color: white,
    margin: 10,
    width: "100%",
  },
  buttonText: {
    color: white,
  },
  chatContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    width: "100%",
  },
  chatOtherText: {
    backgroundColor: primary,
    borderRadius: 10,
    color: white,
    padding: 10,
  },
  chatUserText: {
    backgroundColor: lightGrey,
    borderRadius: 10,
    color: black,
    padding: 10,
  },
  container: {
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    width: "100%",
  },
  icon: {
    fontSize: 32,
    height: 32,
    width: 32,
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textInput: {
    width: "100%",
  },
});

export default Chat;
