import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text, Button, Modal, List } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { darkGrey, white } from "../../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../../utils/ajax";

import RacialBackground from "../checkboxes/RacialBackground";
import Cannabis from "../radios/Cannabis";
import Drinking from "../radios/Drinking";
import EducationLevel from "../radios/EducationLevel";
import Gender from "../radios/Gender";
import HasChildren from "../radios/HasChildren";
import Income from "../radios/Income";
import MaritalStatus from "../radios/MartialStatus";
import PoliticalViews from "../radios/PoliticalViews";
import Religion from "../radios/Religion";
import Smoking from "../radios/Smoking";
import WantsChildren from "../radios/WantsChildren";
import Occupation from "../texts/Occupation";

const PersonalDetails: React.FC<any> = ({ route, edit = false }) => {
  const navigation = useNavigation();

  const [gender, setGender] = React.useState("");
  const [genderModal, setGenderModal] = React.useState(false);

  const [smoking, setSmoking] = React.useState("");
  const [smokingModal, setSmokingModal] = React.useState(false);

  const [cannabis, setCannabis] = React.useState("");
  const [cannabisModal, setCannabisModal] = React.useState(false);

  const [educationLevel, setEducationLevel] = React.useState("");
  const [educationLevelModal, setEducationLevelModal] = React.useState(false);

  const [politicalViews, setPoliticalViews] = React.useState("");
  const [politicalViewsModal, setPoliticalViewsModal] = React.useState(false);

  const [drinking, setDrinking] = React.useState("");
  const [drinkingModal, setDrinkingModal] = React.useState(false);

  const [hasChildren, setHasChildren] = React.useState("");
  const [hasChildrenModal, setHasChildrenModal] = React.useState(false);

  const [wantsChildren, setWantsChildren] = React.useState("");
  const [wantsChildrenModal, setWantsChildrenModal] = React.useState(false);

  const [maritalStatus, setMaritalStatus] = React.useState("");
  const [maritalStatusModal, setMaritalStatusModal] = React.useState(false);

  const [income, setIncome] = React.useState("");
  const [incomeModal, setIncomeModal] = React.useState(false);

  const [religion, setReligion] = React.useState("");
  const [religionModal, setReligionModal] = React.useState(false);

  const [occupation, setOccupation] = React.useState("");
  const [occupationModal, setOccupationModal] = React.useState(false);

  const [racialBackground, setRacialBackground] = React.useState("");
  const [racialBackgroundModal, setRacialBackgroundModal] =
    React.useState(false);

  const LIST_OF_PERSONAL_DETAILS = [
    {
      title: "Gender",
      onPress: () => {
        setGenderModal(true);
      },
      value: gender,
    },
    {
      title: "Education Level",
      onPress: () => {
        setEducationLevelModal(true);
      },
      value: educationLevel,
    },
    {
      title: "Smoking",
      onPress: () => {
        setSmokingModal(true);
      },
      value: smoking,
    },
    {
      title: "Cannabis",
      onPress: () => {
        setCannabisModal(true);
      },
      value: cannabis,
    },
    {
      title: "Political Views",
      onPress: () => {
        setPoliticalViewsModal(true);
      },
      value: politicalViews,
    },
    {
      title: "Drinking",
      onPress: () => {
        setDrinkingModal(true);
      },
      value: drinking,
    },
    {
      title: "Religion",
      onPress: () => {
        setReligionModal(true);
      },
      value: religion,
    },
    {
      title: "Income",
      onPress: () => {
        setIncomeModal(true);
      },
      value: income,
    },
    {
      title: "Marital Status",
      onPress: () => {
        setMaritalStatusModal(true);
      },
      value: maritalStatus,
    },
    {
      title: "Has Children",
      onPress: () => {
        setHasChildrenModal(true);
      },
      value: hasChildren,
    },
    {
      title: "Wants Children",
      onPress: () => {
        setWantsChildrenModal(true);
      },
      value: wantsChildren,
    },
    {
      title: "Occupation",
      onPress: () => {
        setOccupationModal(true);
      },
      value: occupation,
    },
    {
      title: "Racial Background",
      onPress: () => {
        setRacialBackgroundModal(true);
      },
      value: racialBackground,
    },
  ];

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const id = route?.params?.user_match_id || route?.params?.id;
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_profile${id ? "/" + id : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      const data = Array.isArray(res?.data) ? res?.data[0] : res?.data;
      setGender(data?.gender);
      setEducationLevel(data?.education_level);
      setSmoking(data?.smoking);
      setCannabis(data?.cannabis);
      setPoliticalViews(data?.political_views);
      setDrinking(data?.drinking);
      setReligion(data?.religion);
      setIncome(data?.income);
      setMaritalStatus(data?.marital_status);
      setHasChildren(data?.has_children);
      setWantsChildren(data?.wants_children);
      setOccupation(data?.occupation);
      setRacialBackground(data?.racial_background);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      {/* <Scroll> */}
      <List
        data={LIST_OF_PERSONAL_DETAILS}
        contentContainerStyle={styles.column}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            key={index}
            style={styles.card}
            onPress={() => {
              item?.onPress();
            }}
          >
            <View style={styles.title}>
              <Text style={{ textAlign: "left" }}>
                {item.title.replace(/,/g, " ")}
              </Text>
            </View>
            <View style={styles.value}>
              <Text style={{ textAlign: "right" }}>
                {item?.value?.replace(/,/g, " ") || "N/A"}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      />
      <Button
        onPress={async () => {
          await fetchProfile();
        }}
      >
        Refresh
      </Button>
      {!!gender && !!edit && (
        <Button onPress={() => navigation.navigate("DatingPreferencesSetup")}>
          Next
        </Button>
      )}
      {/* </Scroll> */}
      <Modal backdropStyle={styles.backdrop} visible={genderModal}>
        <Gender edit />
        <Button
          onPress={() => {
            setGenderModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={educationLevelModal}>
        <EducationLevel edit />
        <Button
          onPress={() => {
            setEducationLevelModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={smokingModal}>
        <Smoking edit />
        <Button
          onPress={() => {
            setSmokingModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={cannabisModal}>
        <Cannabis edit />
        <Button
          onPress={() => {
            setCannabisModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={politicalViewsModal}>
        <PoliticalViews edit />
        <Button
          onPress={() => {
            setPoliticalViewsModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={drinkingModal}>
        <Drinking edit />
        <Button
          onPress={() => {
            setDrinkingModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={religionModal}>
        <Religion edit />
        <Button
          onPress={() => {
            setReligionModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={incomeModal}>
        <Income edit />
        <Button
          onPress={() => {
            setIncomeModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={maritalStatusModal}>
        <MaritalStatus edit />
        <Button
          onPress={() => {
            setMaritalStatusModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={hasChildrenModal}>
        <HasChildren edit />
        <Button
          onPress={() => {
            setHasChildrenModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={wantsChildrenModal}>
        <WantsChildren edit />
        <Button
          onPress={() => {
            setWantsChildrenModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={occupationModal}>
        <Occupation edit />
        <Button
          onPress={() => {
            setOccupationModal(false);
            fetchProfile();
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={racialBackgroundModal}>
        <RacialBackground edit />
        <Button
          onPress={() => {
            setRacialBackgroundModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    alignItems: "center",
    borderColor: "grey",
    borderRadius: 5,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    height: 50,
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
  },
  column: {
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    alignContent: "center",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    paddingHorizontal: 40,
    width: "100%",
  },
  title: {
    display: "flex",
    fontSize: 20,
    fontWeight: "bold",
    height: "100%",
    justifyContent: "center",
    width: "25%",
  },
  value: {
    color: "grey",
    display: "flex",
    fontSize: 20,
    height: "100%",
    justifyContent: "center",
    width: "50%",
  },
});

export default PersonalDetails;
