import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { Pressable, StyleSheet, Text, View } from "react-native";

import { menuConfig } from "../config/screens";
import { darkGrey, white, grey } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";

const Menu: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  const loggingOut = async (): Promise<void> => {
    await AsyncStorage.multiRemove([
      "token",
      "phoneNumber",
      "isActive",
      "deletedAt",
    ]);
    setRoute("Login");
    navigation.push("Login");
  };

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      {menuConfig.map((item, index) => {
        return (
          <Pressable
            key={index}
            onPress={() => {
              setRoute(item?.pageName);
              navigation.navigate(item?.pageName);
            }}
            style={styles.section}
            disabled={item.locked}
          >
            <View style={styles.section}>
              <View>
                {item.locked && <Text style={styles.lockerText}>🔒</Text>}
              </View>
              <Text style={{ color: darkGrey }}>{item.name}</Text>
            </View>
          </Pressable>
        );
      })}
      <Pressable
        onPress={() => {
          loggingOut().catch(console.error);
        }}
        style={styles.section}
      >
        <View style={styles.section}>
          <Text style={{ color: darkGrey }}>Logout</Text>
        </View>
      </Pressable>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    width: "100%",
  },
  lockerText: {
    color: grey,
  },
  section: {
    alignItems: "center",
    borderColor: grey,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    height: 50,
    justifyContent: "center",
    marginVertical: 1,
    width: "100%",
  },
});

export default Menu;
