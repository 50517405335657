import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import { default as UserDatingPreferences } from "../components/pages/DatingPreferences";
import Scroll from "../components/scroll/Scroll";
import { darkGrey, white } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";

const DatingPreferencesSetup: React.FC<any> = (props) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
  }, [setRoute, props?.route?.name, props?.navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Scroll>
        <UserDatingPreferences {...props} edit />
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
});

export default DatingPreferencesSetup;
