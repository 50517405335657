import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Card, Layout, List } from "@ui-kitten/components";
import axios from "axios";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Loader from "../components/loaders/Spinner";
import { darkGrey, white } from "../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";
import DeckSwiper from "react-native-deck-swiper";
import moment from "moment";
import Swiper from "react-native-update-swiper";
import ImageSlider from "app/components/images/Slider";
import { SafeAreaView } from "react-native-safe-area-context";

const Swiping: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [data, setData]: any = React.useState([]);
  const [photo, setPhoto]: any = React.useState(0);
  const [index, setIndex] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const resetErrorMessage = (): void => {
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const undoMatch = async (id: number): Promise<void> => {
    try {
      const token = await AsyncStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      await axios.post(
        `${HOSTNAME}/api/v1/undo_match`,
        { match_id: id },
        { headers }
      );
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const rejectMatch = async (id: number): Promise<void> => {
    try {
      const token = await AsyncStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      await axios.post(
        `${HOSTNAME}/api/v1/reject_match`,
        { match_id: id },
        { headers }
      );
      setIndex(index + 1);
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const acceptMatch = async (id: number): Promise<void> => {
    try {
      const token = await AsyncStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      await axios.post(
        `${HOSTNAME}/api/v1/accept_match`,
        { match_id: id },
        { headers }
      );
      setIndex(index + 1);
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const setPhotos = (photos: any): any => {
    let results = [];
    let positions = photos.map((_: string, i: number) => i + 1);
    positions = [positions.length, 1, ...positions.slice(1)];
    for (let i = 0; i < photos.length; i++) {
      const position = positions[i];
      const photo = photos.find((p: any) => p.position === position);
      if (photo) {
        results.push(photo);
      }
    }
    return results;
  };

  const fetchData = async (data: any): Promise<void> => {
    setRoute(route?.name);
    const { offset = 0, distance = 3, limit = 25 } = data || {};
    try {
      const token = await AsyncStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axios.get(
        `${HOSTNAME}/api/v1/ranked_users?offset=${offset}&distance=${distance}&limit=${limit}`,
        {
          headers,
        }
      );
      if (res?.data?.error) {
        setError(res?.data?.error);
        resetErrorMessage();
      } else {
        setData(res?.data?.data);
        setError("");
      }
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData({ offset: offset, limit: 25, distance: 3 }).catch(console.error);
  }, [offset, navigation]);

  React.useEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name, navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <View style={styles.row}>
        <View />
        <View />
        <View>
          <Button
            onPress={() => {
              setIndex(0);
              fetchData({ offset: offset, limit: 25, distance: 3 });
            }}
            disabled={loading}
            appearance="ghost"
            status="basic"
          >
            ⟳
          </Button>
        </View>
      </View>
      {!loading && error?.length > 0 && <Text>{error}</Text>}
      {loading && <Loader />}
      {!loading &&
        !!data?.length &&
        data.length > 0 &&
        index <= data.length - 1 && (
          <DeckSwiper
            showSecondCard={false}
            cards={data}
            cardIndex={index}
            onSwiped={() => setIndex(index + 1)}
            verticalSwipe={false}
            backgroundColor={white}
            onSwipedAll={() => setOffset(offset + data.length)}
            onSwipedLeft={() => rejectMatch(data[index]?.profile?.user_id)}
            onSwipedRight={() => acceptMatch(data[index]?.profile?.user_id)}
            containerStyle={Object.assign({}, styles.container, {
              backgroundColor: white,
            })}
            cardStyle={Object.assign({}, styles.card, {
              backgroundColor: white,
            })}
            stackSize={data?.length}
            renderCard={(card: any) => (
              <Card
                style={Object.assign({}, styles.fullHeight, styles.fullWidth, {
                  backgroundColor: white,
                })}
              >
                <View style={styles.column}>
                  <View style={styles.center}>
                    <SafeAreaView style={styles.row}>
                      <ImageSlider
                        style={styles.swiper}
                        images={setPhotos(card?.photos).map(
                          (e: any) => e.photo_url
                        )}
                      />
                    </SafeAreaView>
                  </View>
                  <View style={styles.center}>
                    <View style={styles.column}>
                      <Text>
                        {moment().diff(card?.profile?.age, "years", false)}
                      </Text>
                      <Text>{card?.profile?.first_name}</Text>
                      <Text>{card?.profile?.occupation}</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={Object.assign({}, styles.spaceBetween, styles.row)}
                >
                  <Button
                    status="danger"
                    appearance="outline"
                    style={styles.dislikeButton}
                    onPress={() => rejectMatch(card?.profile?.user_id)}
                  >
                    <Text style={styles.dislikeButton}>X</Text>
                  </Button>
                  <Button
                    status="warning"
                    appearance="outline"
                    style={styles.undoButton}
                    onPress={async () => {
                      setIndex(index - 1);
                      await undoMatch(data[index]?.profile?.user_id);
                      await fetchData({
                        offset: offset,
                        limit: 25,
                        distance: 3,
                      });
                    }}
                  >
                    <Text style={styles.undoButton}>⟳</Text>
                  </Button>
                  <Button
                    status="success"
                    appearance="outline"
                    style={styles.likeButton}
                    onPress={() => acceptMatch(card?.profile?.user_id)}
                  >
                    <Text style={styles.likeButton}>♡</Text>
                  </Button>
                </View>
              </Card>
            )}
          />
        )}
      {!loading && data.length === 0 && <Text>No users found.</Text>}
    </Layout>
  );
};

const styles = StyleSheet.create({
  card: {
    width: "90%",
    height: "90%",
    shadowColor: darkGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: 10,
  },
  center: {
    alignItems: "center",
  },
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  column: {
    flexDirection: "column",
    alignItems: "center",
  },
  dislikeButton: {
    borderRadius: 50,
    height: 50,
    width: 50,
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  halfHeight: {
    height: "50%",
  },
  halfWidth: {
    width: "50%",
  },
  image: {
    height: "60%",
    width: "60%",
    minWidth: 450,
    minHeight: 500,
  },
  likeButton: {
    borderRadius: 50,
    height: 50,
    width: 50,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
    width: "100%",
  },
  swiper: {
    width: "100%",
    height: Platform.OS === "web" ? 650 : 450,
  },
  undoButton: {
    borderRadius: 50,
    height: 50,
    width: 50,
  },
});

export default Swiping;
