import * as React from "react";

import * as eva from "@eva-design/eva";
import * as Sentry from "@sentry/browser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ApplicationProvider } from "@ui-kitten/components";
import * as evaIcons from "@ui-kitten/eva-icons";
import axios from "axios";
import * as Font from "expo-font";
import { StyleSheet } from "react-native";
import { AutocompleteDropdownContextProvider } from "react-native-autocomplete-dropdown";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { PaperProvider, MD3LightTheme as LightTheme } from "react-native-paper";
import { linkConfig, screenConfig } from "./config/screens";
import { myTheme as theme } from "./styles/custom-theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "./utils/ajax";
import { AuthContext } from "./utils/context";
import cors from "./config/cors.json";
import packageJSON from "../package.json";
import { primary } from "./styles/theme";
import { SafeAreaView, SafeAreaProvider } from "react-native-safe-area-context";

const App: React.FC<any> = (props) => {
  const [token, setToken] = React.useState("");
  const [route, setRoute] = React.useState("");

  const navigation = React.useRef();

  const appContextValue = React.useMemo(
    () => ({ route, setRoute, token, setToken }),
    [route, setRoute]
  );

  const fetchFonts = async (): Promise<void> => {
    await Font.loadAsync({
      Helvetica: require("../assets/fonts/Helvetica/Helvetica.ttf"),
      "Helvetica-Bold": require("../assets/fonts/Helvetica/Helvetica-Bold.ttf"),
      Futura: require("../assets/fonts/Futura/FuturaPTHeavy.otf"),
    });
  };

  const Stack = createNativeStackNavigator();

  Sentry.init({
    dsn: "https://ecd6223aeac5ee0a5fdf14617ba3b2ed@o4507245944569856.ingest.us.sentry.io/4507245958397952",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [...cors, "localhost"],
    release: `${packageJSON.name}@${packageJSON.version}`,
  });

  React.useLayoutEffect(() => {
    fetchFonts().catch(console.error);
  }, []);

  React.useLayoutEffect(() => {
    const checkToken = async (): Promise<void> => {
      try {
        const jwt = await AsyncStorage.getItem("token");
        if (!jwt) throw new Error("Token expired");

        const headers = { Authorization: `Bearer ${jwt}` };
        const res = await axios.get(`${HOSTNAME}/api/v1/me`, {
          headers,
        });
        if (res?.data?.error) {
          throw new Error(res?.data);
        }
        setToken(jwt || "");
        await AsyncStorage.multiSet([
          ["userId", String(res?.data?.id) || ""],
          ["phoneNumber", res?.data?.phone_number || ""],
          ["isActive", String(res?.data?.is_active) || ""],
          ["token", jwt || ""],
          ["deletedAt", res?.data?.deleted_at || ""],
          ["subscription", res?.data?.subscription_id || ""],
        ]);
      } catch (error: any) {
        await AsyncStorage.multiRemove([
          "userId",
          "token",
          "phoneNumber",
          "isActive",
          "deletedAt",
          "subscription",
        ]);
        setToken("");
        setRoute("Login");
        props?.navigation?.navigate("Login", { message: "Token expired" });
        await redirectToHomepageWhenExpired(error, navigation);
      }
    };

    checkToken().catch(console.error);
  }, [route, props.navigation]);

  const themeObject = { ...theme, ...eva.light };

  return (
    <AuthContext.Provider value={appContextValue}>
      <ApplicationProvider {...eva} {...evaIcons} theme={themeObject}>
        <PaperProvider theme={LightTheme}>
          <GestureHandlerRootView style={styles.container}>
            <AutocompleteDropdownContextProvider>
              <SafeAreaProvider>
                <NavigationContainer
                  ref={navigation.current}
                  linking={linkConfig}
                >
                  <Stack.Navigator
                    initialRouteName={"Index"}
                    screenOptions={{
                      headerStyle: { backgroundColor: primary },
                      headerBackVisible: false,
                      headerLeft: null,
                    }}
                  >
                    {screenConfig.map((screen: any, index: number) => (
                      <Stack.Screen key={index} {...screen} />
                    ))}
                  </Stack.Navigator>
                </NavigationContainer>
              </SafeAreaProvider>
            </AutocompleteDropdownContextProvider>
          </GestureHandlerRootView>
        </PaperProvider>
      </ApplicationProvider>
    </AuthContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default App;
