import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  Platform,
  useWindowDimensions,
} from "react-native";
import Svg, { Rect } from "react-native-svg";
import { useNavigation } from "@react-navigation/native";
import { darkGrey, mdBreakPoint, primary, white } from "../../styles/theme";
import { AuthContext, type AuthContextProps } from "../../utils/context";

export const Header: React.FC<any> = (props) => {
  return (
    <View style={styles.row}>
      <HeaderLeft {...props} />
      <HeaderRight {...props} />
    </View>
  );
};

export const TwoWayAuthHeader: React.FC<any> = (props) => {
  return (
    <View style={styles.row}>
      <HeaderTwoWayAuthLeft {...props} />
      <HeaderTwoWayAuthRight {...props} />
    </View>
  );
};

export const AuthHeader: React.FC<any> = (props) => {
  return (
    <View style={styles.row}>
      <HeaderAuthLeft {...props} />
      <HeaderAuthRight {...props} />
    </View>
  );
};

export const HeaderLeft: React.FC<any> = () => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const navigation = useNavigation();

  const { width } = useWindowDimensions();
  const [token, setToken] = React.useState<string>("");
  const [isActive, setIsActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getToken = async () => {
      const token = await AsyncStorage.getItem("token");
      setToken(token ?? "");

      const active = await AsyncStorage.getItem("isActive");
      setIsActive(Boolean(active));
    };
    getToken().catch(console.error);
  }, [token, isActive]);

  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          setRoute(token ? "Home" : "Index");
          navigation.navigate(token ? "Home" : "Index");
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: white,
          })}
        >
          {token && isActive ? "Dashboard" : "Home"}
        </Text>
      </Pressable>
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Pricing");
            navigation.navigate("Pricing");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Pricing
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("About");
            navigation.navigate("About");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            About
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Contact");
            navigation.navigate("Contact");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Contact
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Private Policy");
            navigation.navigate("Private Policy");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Private Policy
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Terms");
            navigation.navigate("Terms");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Terms & Conditions
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Returns");
            navigation.navigate("Returns");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Return Policy
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Shipping Policy");
            navigation.navigate("Shipping");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Shipping Policy
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
    </View>
  );
};

export const HeaderTwoWayAuthRight = () => {
  return <View style={styles.background}></View>;
};

export const HeaderRight: React.FC<any> = ({ route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const navigation = useNavigation();

  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          setRoute("Login");
          navigation.navigate("Login");
        }}
      >
        {
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Login
          </Text>
        }
      </Pressable>
      <Pressable
        onPress={() => {
          setRoute("Sign Up");
          navigation.navigate("Sign Up");
        }}
      >
        {
          <Text
            style={Object.assign({}, styles.text, {
              color: white,
            })}
          >
            Sign Up
          </Text>
        }
      </Pressable>
    </View>
  );
};

export const HeaderAuthLeft: React.FC<any> = ({ route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const navigation = useNavigation();
  const menuOn = (): void => {
    navigation.push("Menu");
  };
  const menuOff = (): void => {
    navigation.pop();
  };

  return (
    <View style={styles.background}>
      <Pressable onPress={route?.name === "Menu" ? menuOff : menuOn}>
        <Svg color={white} viewBox="0 0 100 80" width="25" height="25">
          <Rect fill={white} width="100" height="15"></Rect>
          <Rect fill={white} y="30" width="100" height="15"></Rect>
          <Rect fill={white} y="60" width="100" height="15"></Rect>
        </Svg>
      </Pressable>
      <Pressable
        onPress={() => {
          setRoute("Home");
          navigation.navigate("Home");
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: white,
          })}
        >
          Home
        </Text>
      </Pressable>
    </View>
  );
};

export const HeaderTwoWayAuthLeft: React.FC<any> = () => {
  const navigation = useNavigation();
  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          navigation.goBack();
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: white,
          })}
        >
          Back
        </Text>
      </Pressable>
    </View>
  );
};

export const HeaderAuthRight: React.FC<any> = () => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const navigation = useNavigation();

  const loggingOut = async (): Promise<void> => {
    await AsyncStorage.multiRemove([
      "token",
      "phoneNumber",
      "isActive",
      "deletedAt",
    ]);
    setRoute("Login");
    navigation.push("Login");
  };

  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          loggingOut().catch(console.error);
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: white,
          })}
        >
          Logout
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: primary,
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  text: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});
