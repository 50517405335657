import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Card, Layout } from "@ui-kitten/components";
import axios from "axios";
import * as ImagePicker from "expo-image-picker";
import { ImageBackground, StyleSheet } from "react-native";

import { darkGrey, primary, white } from "../../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../../utils/ajax";
import { AuthContext, AuthContextProps } from "../../utils/context";

const Gallery: React.FC<any> = ({ route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const createFormData = (photo: any, body: any = {}) => {
    const data = new FormData();
    data.append("file", photo.uri);
    data.append("filename", photo.fileName);
    data.append("content_type", photo.mimeType);
    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });
    return data;
  };

  const [photo1, setPhoto1] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo2, setPhoto2] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo3, setPhoto3] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo4, setPhoto4] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo5, setPhoto5] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo6, setPhoto6] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo7, setPhoto7] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo8, setPhoto8] = React.useState(`${HOSTNAME}/statics/icon.png`);
  const [photo9, setPhoto9] = React.useState(`${HOSTNAME}/statics/icon.png`);

  const positionToPhotoData = (position: number) => {
    switch (position) {
      case 1:
        return { photo: photo1, setPhoto: setPhoto1 };
      case 2:
        return { photo: photo2, setPhoto: setPhoto2 };
      case 3:
        return { photo: photo3, setPhoto: setPhoto3 };
      case 4:
        return { photo: photo4, setPhoto: setPhoto4 };
      case 5:
        return { photo: photo5, setPhoto: setPhoto5 };
      case 6:
        return { photo: photo6, setPhoto: setPhoto6 };
      case 7:
        return { photo: photo7, setPhoto: setPhoto7 };
      case 8:
        return { photo: photo8, setPhoto: setPhoto8 };
      case 9:
        return { photo: photo9, setPhoto: setPhoto9 };
      default:
        return { photo: "", setPhoto: () => {} };
    }
  };

  const fetchPhotos = async () => {
    try {
      if (route?.params?.user_match_id) return;

      const token = await AsyncStorage.getItem("token");
      const id = route?.params?.user_match_id || route?.params?.id;
      const res = await axios.get(
        `${HOSTNAME}/api/v1/user_photos${id ? "/" + id : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      (res?.data?.data || []).forEach((photo: any) => {
        const { setPhoto } = positionToPhotoData(photo.position);
        setPhoto(photo.photo_url);
      });
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const handleChoosePhoto = (position: number) => async () => {
    const result = await ImagePicker.launchImageLibraryAsync();
    if (!result.canceled) {
      handleUploadPhoto(result.assets[0], { position });
      fetchPhotos();
    }
  };

  const handleUploadPhoto = async (image: any, body: any = {}) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const res = await axios.post(
        `${HOSTNAME}/api/v1/upload`,
        createFormData(image, body),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { setPhoto } = positionToPhotoData(res?.data?.position);
      setPhoto(res?.data?.url);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  React.useEffect(() => {
    const fetchAllPhotos = async () => {
      try {
        const token = await AsyncStorage.getItem("token");
        const id = route?.params?.user_match_id || route?.params?.id;
        const res = await axios.get(
          `${HOSTNAME}/api/v1/user_photos${id ? "/" + id : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        (res?.data?.data || []).forEach((photo: any) => {
          const { setPhoto } = positionToPhotoData(photo.position);
          setPhoto(photo.photo_url);
        });
      } catch (error) {
        console.error(error);
        await redirectToHomepageWhenExpired(error, navigation);
      }
    };

    fetchAllPhotos();
  }, [photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Layout style={styles.column}>
        {[
          [
            { photo: photo1, position: 1 },
            { photo: photo2, position: 2 },
            { photo: photo3, position: 3 },
          ],
          [
            { photo: photo4, position: 4 },
            { photo: photo5, position: 5 },
            { photo: photo6, position: 6 },
          ],
          [
            { photo: photo7, position: 7 },
            { photo: photo8, position: 8 },
            { photo: photo9, position: 9 },
          ],
        ].map((photos, index) => (
          <Layout style={styles.row} key={index}>
            {photos.map((photo, key) => (
              <Card
                style={styles.row}
                key={`${index}-${key}`}
                onPress={edit && handleChoosePhoto(photo.position)}
              >
                <ImageBackground
                  style={styles.picture}
                  source={{ uri: photo.photo }}
                ></ImageBackground>
              </Card>
            ))}
          </Layout>
        ))}
      </Layout>
    </Layout>
  );
};

const styles = StyleSheet.create({
  column: {
    flexDirection: "column",
  },
  container: {
    alignItems: "center",

    justifyContent: "center",
  },
  icon: {
    color: primary,
    height: 32,
    width: 32,
  },
  picture: {
    height: 128,
    resizeMode: "stretch",
    width: 128,
  },
  row: {
    flexDirection: "row",
  },
});

export default Gallery;
