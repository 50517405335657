import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text } from "@ui-kitten/components";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { darkGrey, white } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";

const Logout: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    const logout = async () => {
      await AsyncStorage.multiRemove([
        "token",
        "theme",
        "userId",
        "phoneNumber",
        "callerId",
        "subscription",
        "deletedAt",
      ]);
      navigation.navigate("Login");
    };
    logout();
    setRoute(route?.name);
  }, [setRoute, route?.name, navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Text>Logout</Text>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Logout;
