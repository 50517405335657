import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text, Button, Modal, List } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { darkGrey, white } from "../../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../../utils/ajax";
import { AuthContext, type AuthContextProps } from "../../utils/context";
import Cannabis from "../checkboxes/Cannabis";
import Drinking from "../checkboxes/Drinking";
import EducationLevel from "../checkboxes/EducationLevel";
import Gender from "../checkboxes/Gender";
import HasChildren from "../checkboxes/HasChildren";
import Income from "../checkboxes/Income";
import MaritalStatus from "../checkboxes/MartialStatus";
import PoliticalViews from "../checkboxes/PoliticalViews";
import RacialBackground from "../checkboxes/DatingPreferencesRacialBackground";
import Religion from "../checkboxes/Religion";
import Smoking from "../checkboxes/Smoking";
import WantsChildren from "../checkboxes/WantsChildren";
import MaxAge from "../radios/MaxAge";
import MinAge from "../radios/MinAge";
import { useNavigation } from "@react-navigation/native";

const DatingPreferences: React.FC<any> = ({ route, edit = false }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [gender, setGender] = React.useState("");
  const [genderModal, setGenderModal] = React.useState(false);

  const [minAge, setMinAge] = React.useState("");
  const [minAgeModal, setMinAgeModal] = React.useState(false);

  const [maxAge, setMaxAge] = React.useState("");
  const [maxAgeModal, setMaxAgeModal] = React.useState(false);

  const [smoking, setSmoking] = React.useState("");
  const [smokingModal, setSmokingModal] = React.useState(false);

  const [cannabis, setCannabis] = React.useState("");
  const [cannabisModal, setCannabisModal] = React.useState(false);

  const [educationLevel, setEducationLevel] = React.useState("");
  const [educationLevelModal, setEducationLevelModal] = React.useState(false);

  const [politicalViews, setPoliticalViews] = React.useState("");
  const [politicalViewsModal, setPoliticalViewsModal] = React.useState(false);

  const [drinking, setDrinking] = React.useState("");
  const [drinkingModal, setDrinkingModal] = React.useState(false);

  const [hasChildren, setHasChildren] = React.useState("");
  const [hasChildrenModal, setHasChildrenModal] = React.useState(false);

  const [wantsChildren, setWantsChildren] = React.useState("");
  const [wantsChildrenModal, setWantsChildrenModal] = React.useState(false);

  const [maritalStatus, setMaritalStatus] = React.useState("");
  const [maritalStatusModal, setMaritalStatusModal] = React.useState(false);

  const [income, setIncome] = React.useState("");
  const [incomeModal, setIncomeModal] = React.useState(false);

  const [religion, setReligion] = React.useState("");
  const [religionModal, setReligionModal] = React.useState(false);

  const [racialBackground, setRacialBackground] = React.useState("");
  const [racialBackgroundModal, setRacialBackgroundModal] =
    React.useState(false);

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      const data = Array.isArray(res?.data) ? res?.data[0] : res?.data;

      setGender(data?.gender);
      setMinAge(data?.min_age);
      setMaxAge(data?.max_age);
      setEducationLevel(data?.education_level);
      setSmoking(data?.smoking);
      setCannabis(data?.cannabis);
      setPoliticalViews(data?.political_views);
      setDrinking(data?.drinking);
      setReligion(data?.religion);
      setIncome(data?.income);
      setMaritalStatus(data?.marital_status);
      setHasChildren(data?.has_children);
      setWantsChildren(data?.wants_children);
      setRacialBackground(data?.racial_background);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const LIST_OF_DATING_PREFERENCES = [
    {
      title: "Gender",
      onPress: () => {
        setGenderModal(true);
      },
      value: gender,
    },
    {
      title: "Min Age",
      onPress: () => {
        setMinAgeModal(true);
      },
      value: minAge,
    },
    {
      title: "Max Age",
      onPress: () => {
        setMaxAgeModal(true);
      },
      value: maxAge,
    },
    {
      title: "Education Level",
      onPress: () => {
        setEducationLevelModal(true);
      },
      value: educationLevel,
    },
    {
      title: "Smoking",
      onPress: () => {
        setSmokingModal(true);
      },
      value: smoking,
    },
    {
      title: "Cannabis",
      onPress: () => {
        setCannabisModal(true);
      },
      value: cannabis,
    },
    {
      title: "Political Views",
      onPress: () => {
        setPoliticalViewsModal(true);
      },
      value: politicalViews,
    },
    {
      title: "Drinking",
      onPress: () => {
        setDrinkingModal(true);
      },
      value: drinking,
    },
    {
      title: "Religion",
      onPress: () => {
        setReligionModal(true);
      },
      value: religion,
    },
    {
      title: "Income",
      onPress: () => {
        setIncomeModal(true);
      },
      value: income,
    },
    {
      title: "Marital Status",
      onPress: () => {
        setMaritalStatusModal(true);
      },
      value: maritalStatus,
    },
    {
      title: "Has Children",
      onPress: () => {
        setHasChildrenModal(true);
      },
      value: hasChildren,
    },
    {
      title: "Wants Children",
      onPress: () => {
        setWantsChildrenModal(true);
      },
      value: wantsChildren,
    },
    {
      title: "Racial Background",
      onPress: () => {
        setRacialBackgroundModal(true);
      },
      value: racialBackground,
    },
  ];

  React.useEffect(() => {
    fetchProfile();
  }, []);

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      {/* <Scroll> */}
      <List
        data={LIST_OF_DATING_PREFERENCES}
        contentContainerStyle={styles.column}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            key={index}
            style={styles.card}
            onPress={() => {
              item.onPress();
            }}
          >
            <View style={styles.title}>
              <Text style={{ textAlign: "left" }}>{item.title}</Text>
            </View>
            <View style={styles.value}>
              <Text style={{ textAlign: "right" }}>{item?.value || "N/A"}</Text>
            </View>
          </TouchableOpacity>
        )}
      />
      <Button
        onPress={async () => {
          await fetchProfile();
        }}
      >
        Refresh
      </Button>
      {!!edit && (
        <Button onPress={() => navigation.navigate("LocationSetup")}>
          Next
        </Button>
      )}
      {/* </Scroll> */}
      <Modal backdropStyle={styles.backdrop} visible={genderModal}>
        <Gender edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setGenderModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={minAgeModal}>
        <MinAge edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setMinAgeModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={maxAgeModal}>
        <MaxAge edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setMaxAgeModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={educationLevelModal}>
        <EducationLevel edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setEducationLevelModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={smokingModal}>
        <Smoking edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setSmokingModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={cannabisModal}>
        <Cannabis edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setCannabisModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={politicalViewsModal}>
        <PoliticalViews edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setPoliticalViewsModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={drinkingModal}>
        <Drinking edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setDrinkingModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={religionModal}>
        <Religion edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setReligionModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={incomeModal}>
        <Income edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setIncomeModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={maritalStatusModal}>
        <MaritalStatus edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setMaritalStatusModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={hasChildrenModal}>
        <HasChildren edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setHasChildrenModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={wantsChildrenModal}>
        <WantsChildren edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setWantsChildrenModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={racialBackgroundModal}>
        <RacialBackground edit refreshData={fetchProfile} />
        <Button
          onPress={() => {
            setRacialBackgroundModal(false);
            fetchProfile();
          }}
        >
          Save
        </Button>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    alignItems: "center",
    borderColor: "grey",
    borderRadius: 5,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    height: 50,
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
  },
  column: {
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    alignContent: "center",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    paddingHorizontal: 40,
    width: "100%",
  },
  title: {
    display: "flex",
    fontSize: 20,
    fontWeight: "bold",
    height: "100%",
    justifyContent: "center",
    width: "25%",
  },
  value: {
    color: "grey",
    display: "flex",
    fontSize: 20,
    height: "100%",
    justifyContent: "center",
    width: "50%",
  },
});

export default DatingPreferences;
