import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text, Card, Button, Input } from "@ui-kitten/components";
import axios from "axios";
import { Platform, StyleSheet } from "react-native";

import Scroll from "../components/scroll/Scroll";
import { darkGrey, green, red, white } from "../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { useNavigation } from "@react-navigation/native";
import { set } from "lodash";

const Account: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [subscription, setSubscription]: any = React.useState({});
  const [data, setData] = React.useState({});

  const changePassword = async () => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        `${HOSTNAME}/api/v1/change_password`,
        {
          phone_number: await AsyncStorage.getItem("phoneNumber"),
          new_password: newPassword,
          old_password: oldPassword,
          confirm_password: confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess("Password changed successfully");
    } catch (error) {
      console.error(error);
      setSuccess("");
      setError(error?.response?.data?.error || error?.message);
      await redirectToHomepageWhenExpired(error, navigation);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/fetch_all_data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data || {});
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.error || error?.message);
      await redirectToHomepageWhenExpired(error, navigation);
    }
    setLoading(false);
  };

  const cancelData = async () => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.delete(`${HOSTNAME}/api/v1/clear_all_data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData({});
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.error || error?.message);
      await redirectToHomepageWhenExpired(error, navigation);
    }
    setLoading(false);
  };

  const downloadJsonFile = async () => {
    try {
      if (!data) {
        return;
      }

      if (Platform.OS !== "web") {
        const { DocumentDirectoryPath, writeFile } = require("react-native-fs");
        await writeFile(
          `${DocumentDirectoryPath}/data.json`,
          JSON.stringify(data),
          "utf8"
        );
      } else if (typeof document !== "undefined") {
        const element = document?.createElement("a");
        const file = new Blob([JSON.stringify(data)], {
          type: "application/json",
        });
        element.href = URL.createObjectURL(file);
        element.download = "data.json";
        document?.body?.appendChild(element);
        element.click();
      }
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  React.useLayoutEffect(() => {
    fetchData();
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Scroll>
        <Text category="h1">Account</Text>
        {!loading && (error?.length || 0) > 0 && (
          <Text style={{ color: red }}>{error}</Text>
        )}
        {!loading && (success?.length || 0) > 0 && (
          <Text style={{ color: green }}>{error}</Text>
        )}
        {loading && <Text>Loading...</Text>}
        <Card>
          <Text>Change Password</Text>
          <Input
            placeholder="Old Password"
            value={oldPassword}
            onChangeText={setOldPassword}
            secureTextEntry
          />
          <Input
            placeholder="New Password"
            value={newPassword}
            onChangeText={setNewPassword}
            secureTextEntry
          />
          <Input
            placeholder="Confirm Password"
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            secureTextEntry
          />
          <Button
            onPress={async () => {
              await changePassword();
            }}
          >
            <Text>Change Password</Text>
          </Button>
        </Card>
        <Card>
          <Text>Current Data</Text>
          <Text>{JSON.stringify(data)}</Text>
          <Button
            onPress={async () => {
              await downloadJsonFile();
            }}
          >
            <Text>Download Data</Text>
          </Button>
          <Button
            onPress={async () => {
              await cancelData();
            }}
          >
            <Text>Clear Data</Text>
          </Button>
        </Card>
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
});

export default Account;
