import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Input, Text } from "@ui-kitten/components";
import axios from "axios";
import {
  StyleSheet,
  View,
  Pressable,
  useWindowDimensions,
  Platform,
} from "react-native";

import ErrorMessage from "../components/errors/Message";
import Loader from "../components/loaders/Spinner";
import {
  black,
  grey,
  maxHeight,
  maxWidth,
  primary,
  red,
  darkGrey,
  white,
} from "../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { useNavigation } from "@react-navigation/native";

const TwoWayAuth: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [twoWayAuth, setTwoWayAuth] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const layout = useWindowDimensions();
  const width = layout.width > maxWidth ? maxWidth : layout.width;
  const height = layout.height > maxHeight ? maxHeight : layout.height;

  const title = { fontSize: width * 0.1 };
  const buttonText = {
    fontSize: width * (Platform.OS === "web" ? 0.025 : 0.05),
  };
  const subContainer = {
    width: width * 0.8,
    height: height * 0.8,
  };

  const resetErrorMessage = (): void => {
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const sendCode = async (): Promise<void> => {
    setLoading(true);

    try {
      const phone =
        (await AsyncStorage.getItem("phoneNumber")) ||
        route?.params?.phone_number;
      const { data } = await axios.post(
        `${HOSTNAME}/api/v1/send_two_way_auth`,
        { phone_number: phone },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data?.error) {
        setError(data?.error);
        resetErrorMessage();
      } else {
        setError("");
      }
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation);
    }

    setLoading(false);
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${HOSTNAME}/api/v1/two_way_auth`,
        { two_auth_code: twoWayAuth },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res?.data?.access_token) {
        setError("Wrong code. Please try again!");
      } else {
        setError("");
        await AsyncStorage.setItem("token", String(res?.data?.access_token));
        await AsyncStorage.setItem(
          "isActive",
          String(Boolean(res?.data?.is_active))
        );
        await AsyncStorage.setItem("deletedAt", String(res?.data?.deleted_at));
        const page = res?.data?.is_active ? "Home" : "ProfileSetup";
        setRoute(page);
        navigation.navigate(page);
      }
      setLoading(false);
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
    }
  };

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const phone = await AsyncStorage.getItem("phoneNumber");
        if (!phone) {
          setRoute("Login");
          navigation.navigate("Login");
        }
        setError("");
      } catch (error: any) {
        setError(error?.response?.data?.error);
        resetErrorMessage();
        await redirectToHomepageWhenExpired(error, navigation);
      }

      setLoading(false);
    };

    setRoute(route?.name);
    fetchData().catch(console.error);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <View style={Object.assign({}, styles.subContainer, subContainer)}>
        <View style={styles.titleContainer}>
          <Text style={Object.assign({}, title, styles.title)}>TwoWayAuth</Text>
        </View>
        <View style={styles.mainSection}>
          <View style={styles.main}>
            <Input
              style={Object.assign({}, styles.textField, {
                color: darkGrey,
              })}
              onChangeText={setTwoWayAuth}
              maxLength={6}
            />
          </View>
        </View>
        {loading && <Loader />}
        <View style={styles.section}>
          <Pressable
            style={Object.assign({}, styles.text, {
              color: darkGrey,
            })}
            disabled={loading}
            onPress={() => {
              sendCode().catch(console.error);
            }}
          >
            <Text
              style={Object.assign({}, styles.text, {
                color: darkGrey,
              })}
            >
              Resend Code
            </Text>
          </Pressable>
        </View>
        {error?.length > 0 && <ErrorMessage message={error} color={red} />}
        <Pressable
          disabled={loading}
          style={Object.assign({}, styles.buttonContainer, {
            color: darkGrey,
          })}
          onPress={() => {
            onSubmit().catch(console.error);
          }}
        >
          <View>
            <Text
              style={Object.assign(
                {},
                buttonText,
                Object.assign({}, styles.buttonText, {
                  color: darkGrey,
                })
              )}
            >
              Continue
            </Text>
          </View>
        </Pressable>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: primary,
    borderRadius: 50,

    display: "flex",
    height: Platform.OS === "web" ? "5%" : "7.5%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "80%",
  },
  buttonText: {
    alignItems: "center",
    backgroundColor: primary,

    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  container: {
    alignItems: "center",

    display: "flex",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  main: {
    alignContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  mainSection: {
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    height: "40%",
    justifyContent: "center",
    width: "80%",
  },
  section: {
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    height: "10%",
    justifyContent: "center",
  },
  subContainer: {
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  text: {
    color: primary,
    display: "flex",
    width: "100%",
  },
  textField: {
    alignItems: "center",
    borderColor: grey,
    borderRadius: 50,
    borderWidth: 2,
    color: black,
    display: "flex",
    height: 50,
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  title: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Futura",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  titleContainer: {
    alignItems: "center",

    display: "flex",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
});

export default TwoWayAuth;
