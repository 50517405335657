import * as React from "react";
import { View, Text, StyleSheet, Pressable, TextInput } from "react-native";
import axios from "axios";
import { darkGrey, white } from "../../styles/theme";
import MenuOptions from "../../components/icons/MenuOptions";
import { HOSTNAME } from "../../utils/ajax";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Modal, Card } from "@ui-kitten/components";

type Props = {
  navigation: any;
  route: any;
  matchId: number | string;
  unmatch?: boolean;
};

const Options: React.FC<Props> = ({ route, matchId, unmatch = false }) => {
  const [visible, setVisible] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [visibleReport, setVisibleReport] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const unmatchUser = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        HOSTNAME + `/api/v1/unmatch`,
        { match_id: matchId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const userReport = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        HOSTNAME + "/api/v1/user_reports",
        {
          user_id: matchId,
          reason,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const userBlock = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        HOSTNAME + "/api/v1/user_blocks",
        {
          user_id: matchId,
          reason,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View style={styles.container}>
      {!visible && (
        <Pressable
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
          }}
          onPress={() => setVisible(!visible)}
        >
          <MenuOptions />
        </Pressable>
      )}
      {visible && (
        <View
          style={Object.assign({}, styles.popup, {
            backgroundColor: white,
          })}
        >
          {unmatch && (
            <Pressable
              onPress={() => {
                setVisible(false);
                setVisibleReport(false);
                unmatchUser();
              }}
            >
              <Text style={{ color: darkGrey }}>Unmatch</Text>
            </Pressable>
          )}
          <Pressable
            onPress={() => {
              setVisible(false);
              setVisibleReport(true);
              setTitle("Block");
            }}
          >
            <Text style={{ color: darkGrey }}>Block</Text>
          </Pressable>
          <Pressable
            onPress={() => {
              setVisible(false);
              setVisibleReport(true);
              setTitle("Report");
            }}
          >
            <Text style={{ color: darkGrey }}>Report</Text>
          </Pressable>
          <Pressable onPress={() => setVisible(false)}>
            <Text style={{ color: darkGrey }}>Cancel</Text>
          </Pressable>
        </View>
      )}
      <Modal
        visible={visibleReport}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisibleReport(false)}
      >
        <Card
          style={Object.assign({}, styles.popup, {
            backgroundColor: white,
          })}
        >
          <Pressable onPress={() => setVisibleReport(false)}>
            <Text>Close</Text>
          </Pressable>
          <Text>{title} User</Text>
          <Text>Reason</Text>
          <TextInput
            style={{
              backgroundColor: white,
              color: darkGrey,
              padding: 10,
              borderRadius: 10,
            }}
            onChangeText={(text) => setReason(text)}
          />
          <Button
            disabled={!reason}
            onPress={() => {
              if (title === "Block") {
                userBlock();
              } else if (title === "Report") {
                userReport();
              }
              setVisibleReport(false);
              setVisible(false);
            }}
          >
            <Text>Submit</Text>
          </Button>
        </Card>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  container: {
    position: "relative",
  },
  popup: {},
});

export default Options;
