import ForgottenPasswordScreen from "../auth/ForgottenPassword";
import ForgottenTwoWayAuthScreen from "../auth/ForgottenTwoWayAuth";
import LoginScreen from "../auth/Login";
import ResetPasswordScreen from "../auth/ResetPassword";
import SignupScreen from "../auth/Signup";
import TwoWayAuthScreen from "../auth/TwoWayAuth";
import {
  authOptions,
  homeOptions,
  twoWayAuthOptions,
} from "../components/headers/Options";
import AgeSetupScreen from "../auth/AgeSetup";
import LocationSetupScreen from "../auth/LocationSetup";
import DatingPreferencesSetupScreen from "../auth/DatingPreferencesSetup";
import PersonalDetailsSetupScreen from "../auth/PersonalDetailsSetup";
import ProfileSetupScreen from "../auth/ProfileSetup";
import AccountScreen from "../features/Account";
import AgeScreen from "../features/Age";
import ChatScreen from "../features/Chat";
import DatingPreferencesScreen from "../features/DatingPreferences";
import HomeScreen from "../features/Home";
import LocationScreen from "../features/Location";
import Logout from "../features/Logout";
import PersonalDetailsScreen from "../features/PersonalDetails";
import ProfileScreen from "../features/Profile";
import ProfileDetailScreen from "../features/ProfileDetail";
import WhoLikesYouScreen from "../features/WhoLikesYou";
import MenuScreen from "../menu/Menu";
import AboutScreen from "../public/About";
import ContactScreen from "../public/Contact";
import IndexScreen from "../public/Index";
import PricingScreen from "../public/Pricing";
import PrivatePolicyScreen from "../public/PrivatePolicy";
import ReturnPolicyScreen from "../public/ReturnPolicy";
import ShippingPolicyScreen from "../public/ShippingPolicy";
import TermsAndConditionsScreen from "../public/TermsAndConditions";
import MatchesScreen from "../features/Matches";
import { primary } from "../styles/theme";
import NotFound from "../public/NotFound";

export const config = [
  {
    name: "Index",
    component: IndexScreen,
    options: homeOptions,
    token: false,
    link: "/",
  },
  {
    name: "About",
    component: AboutScreen,
    options: homeOptions,
    token: false,
    link: "/about",
  },
  {
    name: "Contact",
    component: ContactScreen,
    options: homeOptions,
    token: false,
    link: "/contact",
  },
  {
    name: "Pricing",
    component: PricingScreen,
    options: homeOptions,
    token: false,
    link: "/pricing",
  },
  {
    name: "Private Policy",
    component: PrivatePolicyScreen,
    options: homeOptions,
    token: false,
    link: "/private-policy",
  },
  {
    name: "Returns",
    component: ReturnPolicyScreen,
    options: homeOptions,
    token: false,
    link: "/return-policy",
  },
  {
    name: "Shipping",
    component: ShippingPolicyScreen,
    options: homeOptions,
    token: false,
    link: "/shipping-policy",
  },
  {
    name: "Terms",
    component: TermsAndConditionsScreen,
    options: homeOptions,
    token: false,
    link: "/terms-and-conditions",
  },
  {
    name: "Login",
    component: LoginScreen,
    options: homeOptions,
    link: "/login",
    token: false,
  },
  {
    name: "Sign Up",
    component: SignupScreen,
    options: homeOptions,
    token: false,
    link: "/signup",
  },
  {
    name: "ForgottenPassword",
    component: ForgottenPasswordScreen,
    options: homeOptions,
    token: false,
    link: "/forgotten-password",
  },
  {
    name: "TwoWayAuth",
    component: TwoWayAuthScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/two-way-auth-page",
  },
  {
    name: "ForgottenTwoWayAuth",
    component: ForgottenTwoWayAuthScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/forgotten-two-way-auth-page",
  },
  {
    name: "DatingPreferencesSetup",
    component: DatingPreferencesSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/dating-preferences-setup",
  },
  {
    name: "PersonalDetailsSetup",
    component: PersonalDetailsSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/personal-details-setup",
  },
  {
    name: "ProfileSetup",
    component: ProfileSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/profile-setup",
  },
  {
    name: "AgeSetup",
    component: AgeSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/age-setup",
  },
  {
    name: "LocationSetup",
    component: LocationSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/location-setup",
  },
  {
    name: "ResetPassword",
    component: ResetPasswordScreen,
    options: twoWayAuthOptions,
    token: true,
    link: "/reset-password",
  },
  {
    name: "Home",
    component: HomeScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/home",
  },
  {
    name: "Matches",
    component: MatchesScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/matches",
  },
  {
    name: "Profile",
    component: ProfileScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/profile",
  },
  {
    name: "ProfileDetails",
    component: ProfileDetailScreen,
    options: authOptions,
    token: true,
    link: "/profile/:userId",
  },
  {
    name: "Personal Details",
    component: PersonalDetailsScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/personal-details",
  },
  {
    name: "Dating Preferences",
    component: DatingPreferencesScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/dating-preferences",
  },
  {
    name: "Who Likes You",
    component: WhoLikesYouScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/who-likes-you",
  },
  {
    name: "Location",
    component: LocationScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/location",
  },
  {
    name: "Age",
    component: AgeScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/age",
  },
  {
    name: "Chat",
    component: ChatScreen,
    options: authOptions,
    token: true,
    link: "/chat",
  },
  {
    name: "Account",
    component: AccountScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/account",
  },
  {
    name: "Menu",
    component: MenuScreen,
    options: authOptions,
    token: true,
    link: "/menu",
  },
  {
    name: "Logout",
    component: Logout,
    options: authOptions,
    token: true,
    link: "/logout",
  },
  {
    name: "NotFound",
    component: NotFound,
    options: homeOptions,
    token: false,
    link: "/404",
  },
];

export const screenConfig = config.map((config) => {
  return {
    name: config.name,
    component: config.component,
    options: config.options,
    token: config.token,
  };
});

export const menuConfig = config
  .filter((config) => config.menu)
  .map((config: any) => {
    return {
      name: config.name,
      pageName: config.name,
      locked: config?.locked || false,
    };
  });

export const linkConfig = {
  initialRouteName: "Index",
  screenOptions: {
    headerStyle: {
      backgroundColor: primary,
    },
    headerBackVisible: false,
  },
  prefixes: [
    "loveindahouse://",
    "https://www.loveindahouse.com",
    "https://beta.loveindahouse.com",
    "https://loveindahouse.com",
    "https://modernmatchmaking.io",
    "https://www.modernmatchmaking.io",
    "https://beta.modernmatchmaking.io",
    ...config.filter((config) => config.link).map((config) => config.link),
  ],
  config: {
    screens: config.reduce((acc, item: any) => {
      return {
        ...acc,
        [item.name]: item?.parse
          ? {
              path: item.link,
              parse: item.parse,
            }
          : item.link,
      };
    }, {}),
  },
};

export const newSceenConfig = (props: any) => ({
  initialRouteName: "Index",
  screenOptions: {
    headerStyle: {
      backgroundColor: primary,
    },
    headerTitleStyle: {
      color: props?.white,
    },
    headerBackVisible: false,
  },
  screens: {
    ...screenConfig.reduce((acc, item: any) => {
      return {
        ...acc,
        [item.name]: {
          screen: item.component,
          options: {
            title: "Love In Da House",
            headerTitle: (p: any) => item.options.headerTitle(p),
            headerBackVisible: false,
          },
          link: {
            path: item.link,
          },
        },
      };
    }, {}),
  },
});
