import * as React from "react";
import { Alert, Pressable, View } from "react-native";
import { Layout, Text, Card, Button, Tooltip } from "@ui-kitten/components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { HOSTNAME } from "../../../utils/ajax";
import axios from "axios";

const TicTacToe = ({ route, date, socket }: any) => {
  const [gameId, setGameId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [callerId, setCallerId] = React.useState("");
  const [userId, setUserId] = React.useState(0);
  const [player, setPlayer] = React.useState("red");
  const [board, setBoard] = React.useState(
    new Array(3).fill(Array(3).fill(null))
  );

  const fetchGames = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/virtual_dates/${
          date?.virtual_date?.id || date?.id
        }/games`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lastGame = data?.games[data?.games?.length - 1];
      const { board, player } = JSON.parse(lastGame?.game_data || "{}");
      if (lastGame) {
        setGameId(lastGame?.id);

        if (board) setBoard(board);
        if (player) setPlayer(player);
      } else {
        createGame({ board, player }).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createGame = async (gameData: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.post(
        `${HOSTNAME}/api/v1/virtual_dates/${
          date?.virtual_date?.id || date?.id
        }/games`,
        {
          name: "tic_tac_toe",
          game_data: JSON.stringify(gameData),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      const lastGame = data?.games[data?.games?.length - 1];
      setGameId(lastGame?.id);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGame = async (gameData: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.put(
        `${HOSTNAME}/api/v1/virtual_dates/${
          date?.virtual_date?.id || date?.id
        }/games/${gameId}`,
        {
          name: "tic_tac_toe",
          game_data: JSON.stringify(gameData?.gameData),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const wsData = ({ board, player }: any) => {
    return {
      board,
      player,
      callerId: route?.params?.userId || route?.params?.userCallerId,
      otherCallerId: route?.params?.matchId || route?.params?.matchCallerId,
    };
  };

  const resetGame = () => {
    const emptyBoard = new Array(3).fill(Array(3).fill(undefined));
    const emptyPlayer = "red";

    setPlayer(emptyPlayer);
    setBoard(emptyBoard);

    createGame({
      board: emptyBoard,
      player: emptyPlayer,
    }).catch(console.error);

    socket.emit("data", {
      type: "newGame",
      gameData: wsData({
        board: emptyBoard,
        player: emptyPlayer,
        callerId,
      }),
    });
  };

  const dropPiece = (column: number, row: number) => {
    let newPlayer: any;
    let newBoard: any;

    newPlayer = player === "red" ? "yellow" : "red";
    newBoard = board.map((row: any) => [...row]).slice();
    newBoard[column][row] = player;

    if (newBoard && newPlayer) {
      setBoard(newBoard);
      setPlayer(newPlayer);

      updateGame({
        id: gameId,
        gameData: { board: newBoard, player: newPlayer },
      }).catch(console.error);

      socket.emit("data", {
        type: "newMove",
        gameData: wsData({
          board: newBoard,
          player: newPlayer,
          callerId,
        }),
      });
    }
  };

  const checkWinner = () => {
    for (let i = 0; i < 3; i++) {
      if (
        board[i][0] &&
        board[i][0] === board[i][1] &&
        board[i][0] === board[i][2]
      ) {
        return board[i][0];
      }
      if (
        board[0][i] &&
        board[0][i] === board[1][i] &&
        board[0][i] === board[2][i]
      ) {
        return board[0][i];
      }
      if (
        board[0][0] &&
        board[0][0] === board[1][1] &&
        board[0][0] === board[2][2]
      ) {
        return board[0][0];
      }
      if (
        board[0][2] &&
        board[0][2] === board[1][1] &&
        board[0][2] === board[2][0]
      ) {
        return board[0][2];
      }
      if (board.every((row: any) => row.every((cell: any) => cell))) {
        return "Draw! No one";
      }
    }
    return null;
  };

  const renderBoard = () => {
    console.log(userId, date, player);
    return board.map((row, rowIndex) => {
      return (
        <View
          key={rowIndex}
          style={{ flexDirection: "column", backgroundColor: "transparent" }}
        >
          {row?.map((cell: any, cellIndex: any) => {
            return (
              <Pressable
                onPress={() => {
                  if (
                    (date?.user_match?.user_id || date?.user_id) === userId &&
                    player === "red"
                  )
                    dropPiece(rowIndex, cellIndex);
                  else if (
                    (date?.user_match?.user_id || date?.user_id) !== userId &&
                    player === "yellow"
                  )
                    dropPiece(rowIndex, cellIndex);
                }}
                key={cellIndex}
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "black",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: cell,
                    borderRadius: 50,
                  }}
                />
              </Pressable>
            );
          })}
        </View>
      );
    });
  };

  const renderIcon = () => (
    <Pressable onPress={() => setVisible(true)}>
      <Text>?</Text>
    </Pressable>
  );

  React.useEffect(() => {
    resetGame();
  }, []);

  React.useEffect(() => {
    const winner = checkWinner();
    if (winner !== null) {
      Alert.alert(`${winner} wins!`);
      resetGame();
    }
  }, [board, player]);

  React.useEffect(() => {
    fetchGames().catch(console.error);

    socket.on("data", (data: any) => {
      if (data?.type === "newGame" || data?.type === "newMove") {
        const { board, player } = data.gameData;
        setBoard(board.map((row: any) => [...row]).slice());
        setPlayer(player);
      }
    });

    return () => {
      socket.off("data");
    };
  }, [socket]);

  React.useEffect(() => {
    async function getCallerId() {
      const callerId = (await AsyncStorage.getItem("callerId")) ?? "";
      setCallerId(callerId);

      const userId = (await AsyncStorage.getItem("userId")) ?? "";
      setUserId(Number(userId));
    }
    getCallerId();
  }, []);

  return (
    <Layout style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <View style={{ backgroundColor: "rgba(0,0,0,0)" }}>
        <Card style={{ backgroundColor: "rgba(0,0,0,0)" }}>
          <Pressable onPress={fetchGames}>
            <Text>⟳</Text>
          </Pressable>
          <Text category="h1">Tic Tac Toe</Text>
          <Tooltip
            anchor={renderIcon}
            visible={visible}
            onBackdropPress={() => setVisible(false)}
          >
            <View>
              <Text>
                Tic Tac Toe is a two-player game where players take turns
                marking a square in a 3x3 grid. The first player to get three
                marks in a row (horizontally, vertically, or diagonally) wins.
                If all squares are filled and no player has three in a row, the
                game is a draw. Players can choose to play as "X" or "O". The
                game is played on a 3x3 grid, and players take turns placing
                their marks in empty squares. The game ends when one player
                successfully places three of their marks in a row, or when all
                squares are filled without a winner, resulting in a draw. The
                game is simple and quick, making it a popular choice for casual
                play.
              </Text>
            </View>
          </Tooltip>
          <Text>
            {((date?.user_match?.user_id || date?.user_id) === userId &&
              player === "red") ||
            ((date?.user_match?.user_id || date?.user_id) !== userId &&
              player === "yellow")
              ? "Your turn"
              : `${player?.toUpperCase()} players turn`}
          </Text>
          <View
            style={{ flexDirection: "row", backgroundColor: "rbga(0,0,0,0.8)" }}
          >
            {renderBoard()}
          </View>
          <Button onPress={resetGame}>Reset Game</Button>
        </Card>
      </View>
    </Layout>
  );
};

export default TicTacToe;
