import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Radio, RadioGroup, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "../../utils/ajax";
import { AuthContext, type AuthContextProps } from "../../utils/context";

const LIST = ["Male", "Female"];

const Gender: React.FC<any> = ({ route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [gender, setGender] = React.useState(undefined);

  const getOptions = (index: number) => {
    return LIST[index];
  };

  const getIndex = (item: any) => {
    return LIST.findIndex((element) => element === item);
  };

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(`${HOSTNAME}/api/v1/user_profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = response?.data;
      setGender(getIndex(res?.data[0]?.gender));
    } catch (error) {
      console.error(error);
    }
  };

  const setEducation = async (index: number) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        {
          gender: getOptions(index),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setGender(getIndex(res?.data[0]?.gender));
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        <Text>Gender</Text>
        <RadioGroup
          selectedIndex={gender}
          onChange={async (index) => {
            await setEducation(index);
          }}
        >
          {LIST.map((item, index) => (
            <Radio checked={gender === index} key={index}>
              {item}
            </Radio>
          ))}
        </RadioGroup>
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default Gender;
