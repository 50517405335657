import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Input, Button, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME, redirectToHomepageWhenExpired } from "../../utils/ajax";
import { AuthContext, type AuthContextProps } from "../../utils/context";
import { useNavigation } from "@react-navigation/native";

const AboutMe: React.FC<any> = ({ route, edit = false }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const id = route?.params?.user_match_id || route?.params?.id;
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_profile${id ? "/" + id : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      const data = Array.isArray(res?.data) ? res?.data[0] : res?.data;
      setBio(data?.about_me);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const setAboutMe = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        { about_me: bio },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setBio(res?.data[0]?.about_me);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
  };

  const [bio, setBio] = React.useState("");

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        {edit && (
          <Input
            placeholder="Bio"
            style={styles.input}
            value={bio}
            size="large"
            onChangeText={(text) => {
              setBio(text);
            }}
          />
        )}
        {!edit && <Text category="p">{bio}</Text>}
        {edit && (
          <Button
            onPress={async () => {
              await setAboutMe();
            }}
          >
            Save
          </Button>
        )}
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default AboutMe;
