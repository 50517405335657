import * as React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import { io } from "socket.io-client";
import { AuthContext, AuthContextProps } from "./context";

export const HOSTNAME =
  process.env.NODE_ENV === "production"
    ? "https://api.loveindahouse.com"
    : "http://localhost:8080";

export const WEBSOCKETS_HOSTNAME =
  process.env.NODE_ENV === "production"
    ? "https://ws.loveindahouse.com"
    : "http://localhost:3500";

export const CHAT_HOSTNAME =
  process.env.NODE_ENV === "production"
    ? "https://chat.loveindahouse.com"
    : "http://localhost:4500";

export const getSocket = (callerId: string, callerOtherId: string) =>
  io(WEBSOCKETS_HOSTNAME, {
    transports: ["websocket"],
    query: { callerId, callerOtherId },
  });

export const redirectToHomepageWhenExpired = async (
  error: any,
  navigation: any
): Promise<void> => {
  if (Number(error?.status) === 401 || Number(error?.status) === 403) {
    await AsyncStorage.multiRemove([
      "token",
      "phoneNumber",
      "isActive",
      "deletedAt",
      "userId",
      "subscription",
      "callerId",
    ]);
    navigation.navigate("Login", { message: "Token expired" });
  }
};

export const fetchSubscription = async () => {
  try {
    const subscription = await AsyncStorage.getItem("subscription");
    if (subscription) return subscription;

    const token = await AsyncStorage.getItem("token");
    const response = await axios.get(`${HOSTNAME}/api/v1/user_subscription`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response?.data;
    return data?.data || {};
  } catch (error) {
    console.error(error);
  }
};
