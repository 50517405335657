import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import { ImageBackground, StyleSheet, View } from "react-native";

import VideoBackground from "../components/video/VideoBackground";
import { darkGrey, primary, white } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { Image } from "react-native-svg";
import { useNavigation } from "@react-navigation/native";
import Footer from "app/components/footer/Footer";

const About: React.FC<any> = ({ route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <VideoBackground style={styles.videoBackground}>
        <View style={styles.subContainer}>
          <View style={styles.titleContianer}>
            <Text style={styles.titleText}>About Us</Text>
          </View>
          <View style={styles.subTitleContainer}>
            <Text style={styles.subTitleText}>
              Welcome to Love In Da House! We are a team of passionate
              individuals who believe in the power of love and connection. Our
              mission is to help people find their perfect match and build
              meaningful relationships. We understand that dating can be tough,
              but we're here to make it easier and more enjoyable. Thank you for
              choosing Love In Da House!
            </Text>
          </View>
        </View>
        <Footer></Footer>
      </VideoBackground>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  subTitleContainer: {
    alignItems: "center",
    height: "100%",
    width: "40%",
    justifyContent: "center",
  },
  subTitleText: {
    color: white,
    fontSize: 16,
  },
  titleContianer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  titleText: {
    color: primary,
    fontSize: 48,
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
  },
});

export default About;
