import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import UserPersonalDetails from "../components/pages/PersonalDetails";
import Scroll from "../components/scroll/Scroll";
import { darkGrey, white } from "../styles/theme";
import { fetchSubscription } from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";

const PersonalDetails: React.FC<any> = (props) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     props.navigation.navigate("Membership Setup");
    //   }
    // });
    setRoute(props?.route?.name);
  }, [setRoute, props?.route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Scroll>
        <View style={styles.row}>
          <View></View>
          <Text category="h1">Personal Details</Text>
          <View></View>
        </View>
        <UserPersonalDetails {...props} setRoute={setRoute} />
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
  row: {
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

export default PersonalDetails;
