import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, CheckBox, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "../../utils/ajax";
import { AuthContext, type AuthContextProps } from "../../utils/context";

const LIST = [
  "Never",
  "Socially",
  "Regularly",
  "Trying to quit",
  "Quit",
  "Vape",
  "Hookah",
  "Cigar",
  "Pipe",
  "Other",
];

const Smoking: React.FC<any> = ({ route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [smoking, setSmoking]: any = React.useState([]);

  const setChecked = (nextChange: boolean, item: any) => {
    const array = (smoking || []).slice(0);
    if (nextChange) {
      array.push(item);
    } else {
      const index = array.findIndex((e: any) => e === item);
      array.splice(index, 1);
    }
    setSmoking(array);
    setSmokings(array);
  };

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setSmoking(res?.data[0]?.smoking?.split(","));
    } catch (error) {
      console.error(error);
    }
  };

  const setSmokings = async (array: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        {
          smoking: array?.join(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setSmoking(res?.data[0]?.smoking?.split(","));
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        <Text>Smoking</Text>
        {LIST.map((item, index) => (
          <CheckBox
            checked={!!smoking?.find((e: any) => e === item)}
            onChange={(nextChecked) => {
              setChecked(nextChecked, item);
            }}
            key={index}
          >
            {item}
          </CheckBox>
        ))}
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default Smoking;
