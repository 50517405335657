import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { ImageBackground, StyleSheet } from "react-native";

import VideoBackground from "../components/video/VideoBackground";
import { darkGrey, white } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { useNavigation } from "@react-navigation/native";
import Footer from "app/components/footer/Footer";

const PrivatePolicy: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <VideoBackground style={styles.videoBackground}>
        <iframe
          method="GET"
          src="/private-policies"
          title="Private Policy"
          width="100%"
          height="100%"
          style={styles.iFrame}
        />
        <Footer></Footer>
      </VideoBackground>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  iFrame: {
    position: "fixed",
    top: 50,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    color: "white",
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
  },
});

export default PrivatePolicy;
