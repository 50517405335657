import { set } from "lodash";
import React, { createRef, useState } from "react";
import {
  View,
  Image,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Dimensions,
  Text,
  TouchableOpacity,
} from "react-native";

const ImageSlider = ({ images = [], style }: any) => {
  const { width, height } = Dimensions.get("window");

  const [active, setActive] = useState(0);
  const [photos, setPhotos] = useState(images);

  const setImage = () => {
    const first = photos.shift();
    photos.push(first);
    setPhotos(photos);
  };

  return (
    <View>
      <ScrollView
        pagingEnabled
        horizontal
        disableIntervalMomentum
        showsHorizontalScrollIndicator={false}
        style={[{ width, height }, style]}
        onScroll={(event) => {
          const slide = Math.round(
            event.nativeEvent.contentOffset.x /
              event.nativeEvent.layoutMeasurement.width
          );
          setActive(slide);
        }}
      >
        {photos.map((image: string, index: number) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              setActive(active + 1 === images.length ? 0 : active + 1);
              setImage();
            }}
          >
            <Image
              key={index}
              source={{ uri: image }}
              style={{ width, height, resizeMode: "cover" }}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>
      <View style={styles.pagination}>
        {images.map((_: unknown, k: number) => (
          <Text key={k} style={k == active ? styles.activeDot : styles.dot}>
            •
          </Text>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pagination: {
    flexDirection: "row",
    position: "absolute",
    bottom: -15,
    alignSelf: "center",
  },
  dot: {
    color: "#888",
    fontSize: 50,
  },
  activeDot: {
    color: "#FAFAFA",
    fontSize: 50,
  },
});

export default ImageSlider;
