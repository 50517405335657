import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link } from "@react-navigation/native";
import { Card, Layout, List, Modal, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, Image, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Loader from "../components/loaders/Spinner";
import { primary, white } from "../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";
import Options from "../components/popups/Options";

const Matches: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const [userId, setUserId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [matches, setMatches] = React.useState([]);
  const [galleryUsers, setGalleryUsers] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const [token, setToken] = React.useState("");

  const fetchUserMatches = async () => {
    setLoading(true);
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/user_matchs`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setMatches(data?.data ?? []);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, navigation);
    }
    setLoading(false);
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchUserMatches().catch(console.error);
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <View style={styles.row}>
        <View></View>
        <Text category="h1">Matches</Text>
        <TouchableOpacity onPress={fetchUserMatches}>
          <Text style={styles.icon}>⟳</Text>
        </TouchableOpacity>
      </View>
      {loading && <Loader />}
      {!loading && !matches.length && (
        <>
          <Text>No matches found. Please try again later.</Text>
        </>
      )}
      <List
        style={styles.list}
        data={matches}
        renderItem={({ item, key }: any) => (
          <Link
            key={key}
            screen={"Chat"}
            params={{
              matchId:
                item?.user_id === Number(userId)
                  ? item?.match?.user_id
                  : item?.user_id,
              userId:
                item?.user_id === Number(userId)
                  ? item?.user_id
                  : item?.match?.user_id,
            }}
          >
            <Card key={key} style={styles.card}>
              <View style={styles.row}>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("Chat", {
                      matchId:
                        item?.user_id === Number(userId)
                          ? item?.match?.user_id
                          : item?.user_id,
                      userId:
                        item?.user_id === Number(userId)
                          ? item?.user_id
                          : item?.match?.user_id,
                    })
                  }
                >
                  <Image
                    style={styles.image}
                    source={{ uri: item?.match_photos[0]?.photo_url }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("Chat", {
                      matchId:
                        item?.user_id === Number(userId)
                          ? item?.match?.user_id
                          : item?.user_id,
                      userId:
                        item?.user_id === Number(userId)
                          ? item?.user_id
                          : item?.match?.user_id,
                    })
                  }
                >
                  <View style={styles.column}>
                    <Text>{item?.match_profile?.first_name}</Text>
                    <Text>{item?.match_profile?.occupation}</Text>
                    <Text>{item?.match_profile?.about_me}</Text>
                  </View>
                </TouchableOpacity>
                <Options
                  navigation={navigation}
                  route={route}
                  matchId={item?.match_profile?.user_id}
                  unmatch
                />
              </View>
            </Card>
          </Link>
        )}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    width: "95%",
    shadowColor: "#000",
    shadowOffset: {
      width: 8,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,
  },
  column: {
    flexDirection: "column",
  },
  container: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  fullscreen: {
    height: "100%",
    width: "100%",
  },
  icon: {
    fontSize: 32,
    height: 32,
    width: 32,
  },
  image: {
    height: 100,
    width: 100,
  },
  list: {
    flexDirection: "column",
    width: "100%",
  },
  outline: {
    borderColor: primary,
    borderWidth: 5,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 50,
    width: "100%",
  },
});

export default Matches;
