import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import Age from "../components/pages/Age";
import { darkGrey, white } from "../styles/theme";
// import { fetchSubscription } from "../utils/ajax";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { useNavigation } from "@react-navigation/native";

const AgeSetup = (props: any) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     props.navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, props?.route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Age {...props} setRoute={setRoute} />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
});

export default AgeSetup;
