import * as React from "react";
import { Alert, Pressable, View } from "react-native";
import { Layout, Text, Card, Button, Tooltip } from "@ui-kitten/components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { HOSTNAME } from "../../../utils/ajax";
import axios from "axios";

const ConnectFour = ({ route, date, socket }: any) => {
  const [gameId, setGameId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [callerId, setCallerId] = React.useState("");
  const [userId, setUserId] = React.useState(0);
  const [player, setPlayer] = React.useState("red");
  const [board, setBoard] = React.useState(
    new Array(7).fill(Array(6).fill(null))
  );

  const fetchGames = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/virtual_dates/${
          date?.virtual_date?.id || date?.id
        }/games`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lastGame = data?.games[data?.games?.length - 1];
      const { board, player } = JSON.parse(lastGame?.game_data || "{}");
      if (lastGame) {
        setGameId(lastGame?.id);

        if (board) setBoard(board);
        if (player) setPlayer(player);
      } else {
        createGame({ board, player }).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createGame = async (gameData: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.post(
        `${HOSTNAME}/api/v1/virtual_dates/${
          date?.virtual_date?.id || date?.id
        }/games`,
        {
          name: "connect_four",
          game_data: JSON.stringify(gameData),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      const lastGame = data?.games[data?.games?.length - 1];
      setGameId(lastGame?.id);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGame = async (gameData: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.put(
        `${HOSTNAME}/api/v1/virtual_dates/${
          date?.virtual_date?.id || date?.id
        }/games/${gameId}`,
        {
          name: "connect_four",
          game_data: JSON.stringify(gameData?.gameData),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const wsData = ({ board, player }: any) => {
    return {
      board,
      player,
      callerId: route?.params?.userId || route?.params?.userCallerId,
      otherCallerId: route?.params?.matchId || route?.params?.matchCallerId,
    };
  };

  const resetGame = () => {
    const emptyBoard = new Array(7).fill(Array(6).fill(undefined));
    const emptyPlayer = "red";

    setPlayer(emptyPlayer);
    setBoard(emptyBoard);

    createGame({
      board: emptyBoard,
      player: emptyPlayer,
    }).catch(console.error);

    socket.emit("data", {
      type: "newGame",
      gameData: wsData({
        board: emptyBoard,
        player: emptyPlayer,
        callerId,
      }),
    });
  };

  const dropPiece = (column: number) => {
    let newPlayer: any;
    let newBoard: any;

    for (let row = 5; row >= 0; row--) {
      if (!board[column][row]) {
        newPlayer = player === "red" ? "yellow" : "red";
        newBoard = board.map((row: any) => [...row]).slice();
        newBoard[column][row] = player;
        break;
      }
    }

    if (newBoard && newPlayer) {
      setBoard(newBoard);
      setPlayer(newPlayer);

      updateGame({
        id: gameId,
        gameData: { board: newBoard, player: newPlayer },
      }).catch(console.error);

      socket.emit("data", {
        type: "newMove",
        gameData: wsData({
          board: newBoard,
          player: newPlayer,
          callerId,
        }),
      });
    }
  };

  const checkWinner = () => {
    for (let row = 0; row < 7; row++) {
      for (let col = 0; col < 6; col++) {
        if (
          col + 3 < 6 &&
          board[row][col] !== null &&
          board[row][col] === board[row][col + 1] &&
          board[row][col] === board[row][col + 2] &&
          board[row][col] === board[row][col + 3]
        ) {
          return board[row][col];
        }
        if (
          row + 3 < 7 &&
          board[row][col] !== null &&
          board[row][col] === board[row + 1][col] &&
          board[row][col] === board[row + 2][col] &&
          board[row][col] === board[row + 3][col]
        ) {
          return board[row][col];
        }
        if (
          row + 3 < 7 &&
          col + 3 < 6 &&
          board[row][col] !== null &&
          board[row][col] === board[row + 1][col + 1] &&
          board[row][col] === board[row + 2][col + 2] &&
          board[row][col] === board[row + 3][col + 3]
        ) {
          return board[row][col];
        }
        if (
          row + 3 < 7 &&
          col - 3 >= 0 &&
          board[row][col] !== null &&
          board[row][col] === board[row + 1][col - 1] &&
          board[row][col] === board[row + 2][col - 2] &&
          board[row][col] === board[row + 3][col - 3]
        ) {
          return board[row][col];
        }
      }
    }
    return null;
  };

  const renderBoard = () => {
    console.log(userId, date, player);
    return board.map((row, rowIndex) => {
      return (
        <View
          key={rowIndex}
          style={{ flexDirection: "column", backgroundColor: "transparent" }}
        >
          {row?.map((cell: any, cellIndex: any) => {
            return (
              <Pressable
                onPress={() => {
                  if (
                    (date?.user_match?.user_id || date?.user_id) === userId &&
                    player === "red"
                  )
                    dropPiece(rowIndex);
                  else if (
                    (date?.user_match?.user_id || date?.user_id) !== userId &&
                    player === "yellow"
                  )
                    dropPiece(rowIndex);
                }}
                key={cellIndex}
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "black",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: cell,
                    borderRadius: 50,
                  }}
                />
              </Pressable>
            );
          })}
        </View>
      );
    });
  };

  const renderIcon = () => (
    <Pressable onPress={() => setVisible(true)}>
      <Text>?</Text>
    </Pressable>
  );

  React.useEffect(() => {
    const winner = checkWinner();
    if (winner !== null) {
      Alert.alert(`${winner} wins!`);
      resetGame();
    }
  }, [player]);

  React.useEffect(() => {
    fetchGames().catch(console.error);

    socket.on("data", (data: any) => {
      if (
        data?.type === "newGame" ||
        data?.type === "newMove" ||
        data?.type === "selectedGame"
      ) {
        const { board, player } = data.gameData;
        setBoard(board.map((row: any) => [...row]).slice());
        setPlayer(player);
      }
    });

    return () => {
      socket.off("data");
    };
  }, [socket]);

  React.useEffect(() => {
    resetGame();
  }, []);

  return (
    <Layout style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <View style={{ backgroundColor: "rgba(0,0,0,0)" }}>
        <Card style={{ backgroundColor: "rgba(0,0,0,0)" }}>
          <Pressable onPress={fetchGames}>
            <Text>⟳</Text>
          </Pressable>
          <Text category="h1">Connect Four</Text>
          <Tooltip
            anchor={renderIcon}
            visible={visible}
            onBackdropPress={() => setVisible(false)}
          >
            <View>
              <Text>
                Connect Four is a two-player connection game in which the
                players first choose a color and then take turns dropping
                colored discs from the top into a seven-column, six-row
                vertically suspended grid. The pieces fall straight down,
                occupying the lowest available space within the column. The
                objective of the game is to be the first to form a horizontal,
                vertical, or diagonal line of four of one's own discs. Connect
                Four is a solved game. The first player can always win by
                playing the right moves.
              </Text>
            </View>
          </Tooltip>
          <Text>
            {((date?.user_match?.user_id || date?.user_id) === userId &&
              player === "red") ||
            ((date?.user_match?.user_id || date?.user_id) !== userId &&
              player === "yellow")
              ? "Your turn"
              : `${player?.toUpperCase()} players turn`}
          </Text>
          <View
            style={{ flexDirection: "row", backgroundColor: "rbga(0,0,0,0.8)" }}
          >
            {renderBoard()}
          </View>
          <Button onPress={resetGame}>Reset Game</Button>
        </Card>
      </View>
    </Layout>
  );
};

export default ConnectFour;
