import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Layout, Text } from "@ui-kitten/components";
import axios from "axios";
import * as Geolocation from "expo-location";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { darkGrey, white } from "../../styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "../../utils/ajax";

const Location: React.FC<any> = (props) => {
  const navigation = useNavigation();
  const [location, setLocation]: any = React.useState(null);

  const getLocation = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/user_locations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLocation(data?.data[data?.data.length - 1]);
    } catch (error) {
      console.error(error);
    }
  };

  const setGeoLocation = async (info: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.post(
        `${HOSTNAME}/api/v1/user_locations`,
        {
          latitude: info.coords.latitude,
          longitude: info.coords.longitude,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLocation(data?.data[data?.data?.length - 1]);
    } catch (error) {
      console.error(error);
      await redirectToHomepageWhenExpired(error, props.props?.setRoute);
    }
  };

  React.useEffect(() => {
    getLocation();
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <Text>City: {location?.city || "N/A"}</Text>
      <Text>State: {location?.state || "N/A"}</Text>
      <Text>Country: {location?.country || "N/A"}</Text>
      <Text>Postal Code: {location?.postal_code || "N/A"}</Text>
      <Button
        onPress={async () => {
          const res = await Geolocation.requestForegroundPermissionsAsync();
          if (res.status === "granted") {
            const info = await Geolocation.getCurrentPositionAsync({});
            setGeoLocation(info);
          }
        }}
      >
        Get Location
      </Button>
      <Button onPress={getLocation}>Refresh</Button>
      {!!location && !!props.edit && (
        <Button onPress={() => props.navigation.navigate("Home")}>Next</Button>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
});

export default Location;
