import * as React from "react";

export const AuthContext = React.createContext({});

export interface AuthContextProps {
  route: string;
  setRoute: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
}
