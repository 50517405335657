import { Text } from "@ui-kitten/components";
import { darkGrey, white } from "app/styles/theme";
import * as React from "react";
import { Image, Platform, StyleSheet, View } from "react-native";

const Footer: React.FC = () => {
  if (Platform.OS !== "web") return;
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../../assets/images/credit-cards.png")}
      />
      <Text style={styles.text}>We accept these payment methods</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "space-around",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: darkGrey,
    width: "100%",
  },
  image: {
    width: 250,
    height: 50,
  },
  text: {
    color: white,
  },
});

export default Footer;
