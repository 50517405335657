import * as React from "react";

import { StyleSheet } from "react-native";

const VideoBackground: React.FC<any> = ({ children }: any) => {
  return (
    <div style={styles.backgroundVideoContainer}>
      <video
        id="video-background"
        autoPlay
        loop
        muted
        style={styles.backgroundVideo}
      >
        <source src={"/assets/video/iStock-858344024.mp4"} type="video/mp4" />
      </video>
      <div style={styles.backgroundVideoContent}>{children}</div>
    </div>
  );
};

const styles = StyleSheet.create({
  backgroundVideo: {
    bottom: 0,
    left: 0,
    margin: "auto",
    minHeight: "50%",
    minWidth: "50%",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
  },
  backgroundVideoContainer: {
    height: "200%",
    left: "-50%",
    position: "absolute",
    top: "-50%",
    width: "200%",
    zIndex: -1,
  },
  backgroundVideoContent: {
    position: "relative",
    top: "27.5%",
    width: "100%",
  },
});

export default VideoBackground;
