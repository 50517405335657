import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import {
  StyleSheet,
  Pressable,
  View,
  Platform,
  useWindowDimensions,
  ImageBackground,
} from "react-native";
import VideoBackground from "../components/video/VideoBackground";
import { darkGrey, maxHeight, maxWidth, primary, white } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { useNavigation } from "@react-navigation/native";
import Footer from "app/components/footer/Footer";

const NotFound: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  const layout = useWindowDimensions();
  const width = layout.width > maxWidth ? maxWidth : layout.width;
  const height = layout.height > maxHeight ? maxHeight : layout.height;

  const title = { fontSize: width * 0.075 };
  const subTitle = { fontSize: width * 0.045 };
  const buttonText = {
    fontSize: width * 0.05,
  };
  const subContainer = {
    height: height * (Platform.OS === "web" ? 0.9 : 0.8),
    width: width * (Platform.OS === "web" ? 1 : 0.8),
  };
  if (Platform.OS === "web") {
    delete subContainer.width;
  }

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <VideoBackground style={styles.videoBackground}>
        <View style={Object.assign({}, styles.subContainer, subContainer)}>
          <View style={styles.titleContainer}>
            <Text style={Object.assign({}, title, styles.title)}>
              Love In Da House
            </Text>
          </View>
          <View>
            <Text style={Object.assign({}, subTitle, styles.subTitle)}>
              Page Not Found
            </Text>
          </View>
        </View>
      </VideoBackground>
      <Footer></Footer>
    </Layout>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: primary,
    borderRadius: 50,
    display: "flex",
    height: "5%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: Platform.OS === "web" ? "40%" : "90%",
    color: white,
  },
  buttonText: {
    alignItems: "center",
    backgroundColor: primary,
    color: white,
    display: "flex",
    fontSize: 24,
    justifyContent: "center",
    padding: 0,
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  image: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  imageContainer: {
    alignItems: "center",
    display: "flex",
    height: "60%",
    justifyContent: "center",
    width: "100%",
  },
  subContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-around",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  subTitle: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    justifyContent: "center",
  },
  subTitleContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Futura",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default NotFound;
