import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import { ImageBackground, StyleSheet, View } from "react-native";
import Hyperlink from "react-native-hyperlink";

import VideoBackground from "../components/video/VideoBackground";
import { darkGrey, primary, white } from "../styles/theme";
import { AuthContext, type AuthContextProps } from "../utils/context";
import { useNavigation } from "@react-navigation/native";
import Footer from "app/components/footer/Footer";

// @ts-expect-error import/first
const Link = Hyperlink?.default ? Hyperlink.default : Hyperlink;

const Contact: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: white,
      })}
    >
      <VideoBackground style={styles.videoBackground}>
        <View style={styles.subContainer}>
          <View style={styles.titleContianer}>
            <Text style={styles.titleText}>Contact</Text>
          </View>
          <View>
            <Text style={{ color: white }}>
              For support, please email{" "}
              <Link style={{ color: white }} linkDefault>
                <Text style={{ color: white }}>info@loveindahouse.com</Text>
              </Link>
            </Text>
            {/* <Text style={{ color: white }}>
              Business Address: 1271 LAKESIDE DRIVE APT 1135 SUNNYVALE CA 94085
              United States
            </Text>
            <Text style={{ color: white }}>Phone: +1 (408) 507-3140</Text> */}
          </View>
        </View>
        <Footer></Footer>
      </VideoBackground>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    alignItems: "center",

    flexDirection: "column",
    justifyContent: "flex-start",
  },
  titleContianer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  titleText: {
    color: primary,
    fontSize: 48,
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
  },
});

export default Contact;
