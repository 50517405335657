import * as React from "react";

import { TwoWayAuthHeader, AuthHeader, Header } from "./Header";

export const homeOptions = {
  headerTitle: (p: any) => <Header {...p} />,
  headerBackVisible: false,
};

export const authOptions = {
  headerTitle: (p: any) => <AuthHeader {...p} />,
  headerBackVisible: false,
};

export const twoWayAuthOptions = {
  headerTitle: (p: any) => <TwoWayAuthHeader {...p} />,
  headerBackVisible: false,
};
